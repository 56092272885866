var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"homepage"}},[_c('header',{staticClass:"fixed-top d-flex align-items-center header-transparent",attrs:{"id":"header"}},[_c('site-header')],1),_c('Carousel',{attrs:{"titlePage":'Văn bản pháp lý'}}),_c('main',{attrs:{"id":"main"}},[_c('section',{staticClass:"team",attrs:{"data-aos":"fade-up","data-aos-easing":"ease-in-out","data-aos-duration":"500"}},[_c('div',{staticClass:"container",attrs:{"id":"legal-document"}},[_c('div',{staticClass:"d-flex"},[_c('feather-icon',{attrs:{"icon":"HomeIcon","size":"20","color":"#0282CD"}}),_c('feather-icon',{staticClass:"ml-1",attrs:{"icon":"ChevronRightIcon","size":"20"}}),_c('div',{staticClass:"titlePage"},[_vm._v(" Danh sách văn bản pháp lý ")])],1),_c('div',{staticClass:"mt-1"},[_c('div',{staticClass:"d-flex justify-content-end"},[_c('div',{staticClass:"header-action"},[_c('search',{staticClass:"search-input",on:{"change":function (value){_vm.urlQuery.key = value
                                    _vm.fetchData(_vm.urlQuery)}}})],1)]),_c('vue-good-table',{ref:"user-table",attrs:{"style-class":"vgt-table","columns":_vm.columns,"rows":_vm.dataList || [],"select-options":{
              enabled: false,
              vertialAlignTop: true,
              selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
              selectionInfoClass: 'custom-class',
              selectionText: 'rows selected',
              clearSelectionText: 'clear',
              disableSelectInfo: true, // disable the select info panel on top
              selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
            }},scopedSlots:_vm._u([{key:"table-column",fn:function(props){return [(props.column.label ==='TextNumber')?_c('span',{staticClass:"text-nowrap"},[_vm._v(" SỐ HIỆU VĂN BẢN ")]):(props.column.label ==='TextName')?_c('span',{staticClass:"text-nowrap"},[_vm._v(" TÊN VĂN BẢN ")]):(props.column.label ==='Abstract')?_c('span',{staticClass:"text-nowrap"},[_vm._v(" TRÍCH YẾU ")]):(props.column.label ==='DateDown')?_c('span',{staticClass:"text-nowrap"},[_vm._v(" NGÀY TẢI LÊN ")]):(props.column.label ==='ActionFunction')?_c('span',{staticClass:"text-nowrap"},[_vm._v(" TẢI XUỐNG ")]):_vm._e()]}},{key:"table-row",fn:function(props){return [(props.column.field === 'TextNumber')?_c('span',[_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(props.row.code))])]):(props.column.field === 'TextName')?_c('span',[_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(props.row.name))])]):(props.column.field === 'Abstract')?_c('span',[_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(props.row.content))])]):(props.column.field === 'DateDown')?_c('span',[_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(_vm._f("formatDateToDDMM")(props.row.created)))])]):(props.column.field === 'ActionFunction' && props.row.filePath)?_c('span',[_c('span',{attrs:{"title":"Tải xuống"},on:{"click":function($event){return _vm.downloadFile(props.row)}}},[_c('feather-icon',{staticClass:"text-body",attrs:{"icon":"DownloadIcon","size":"18"}})],1)]):_vm._e()]}}])}),_c('my-pagination',{attrs:{"totalItems":_vm.totalRecord,"currentPage":_vm.urlQuery.pageNumber},on:{"pageClick":function (pageNumber, pageSize){
              _vm.urlQuery.pageNumber = pageNumber
              _vm.urlQuery.pageSize = pageSize
              _vm.fetchData(_vm.urlQuery)
            }}})],1)])])]),_c('footer',{attrs:{"id":"footer","data-aos":"fade-up","data-aos-easing":"ease-in-out","data-aos-duration":"500"}},[_c('site-footer')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }